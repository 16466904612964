@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");


.rowwing {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourapproach h1{
  margin: 2rem;
  color: orange;

}
.box .content{
  text-align: justify;
  padding: 1rem;
}

.rowwing .box {
  margin: 2rem;
  /* margin: 0 auto; */
  border: 1px solid;
  border-radius: 20px;
  width: 300px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: color 0.5s ease-in;
}

.rowwing .box .icon,
.rowwing .box .content {
  margin: 50px 0;
}

.rowwing .box .icon {
  width: 80px;
  height: 80px;
  display: grid;
  place-content: center;
  font-size: 250%;
  border-radius: 100%;
  color: #f7f7f7;
  transition: box-shadow 0.5s ease-in;
}

.rowwing .box:hover {
  color: #fff;
}

.rowwing .box:nth-of-type(1) .icon {
  background-color: #ff9900;
  box-shadow: 0px 0px 0px 0px #ff9900;
}

.rowwing .box:nth-of-type(1):hover .icon {
  box-shadow: 0px 0px 0px 400px #ff9900;
}

.rowwing .box:nth-of-type(2) .icon {
  background-color: #5158bb;
  box-shadow: 0px 0px 0px 0px #5158bb;
}

.rowwing .box:nth-of-type(2):hover .icon {
  box-shadow: 0px 0px 0px 400px #5158bb;
}

.rowwing .box:nth-of-type(3) .icon {
  background-color: #11adad;
  box-shadow: 0px 0px 0px 0px #11adad;
}

.rowwing .box:nth-of-type(3):hover .icon {
  box-shadow: 0px 0px 0px 400px #11adad;
}


@media only screen and (max-width: 600px) {
  .rowwing{
    flex-direction: column;
  }
  .rowwing .box{
    margin: 1rem;
  }
}