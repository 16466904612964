.studymaterial {
  width: 25%;
}

.studymaterial h2 {
  color: var(--main--black);
  /* background-color: var(--main-yellow); */
  background-image: url(https://whitbyshores.ddsb.ca/en/resourcesGeneral/Featured-Image/newsletterpicture.jpg);
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
    .studymaterial {
        width: 100%;
      }
}
