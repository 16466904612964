:root {
  --main-black: black;
  --main-red: #f50a00;
  --main-blue: #1632b6;
  --main-black: #12129d;
  --main-yellow: #FFCA03;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th,
td {
  padding: 15px;
  text-align: center;
}

th {
  background-color: #FFCA03;
  color: #fff;
}

td {
  border: 1px solid #dee2e6;
}
.container h2 {
  color: var(--main--black);
  /* background-color: var(--main-yellow); */
  background-image: url(https://whitbyshores.ddsb.ca/en/resourcesGeneral/Featured-Image/newsletterpicture.jpg);
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
}
td{
    padding: 0.2rem !important;
}


@media screen and (max-width: 600px) {

}