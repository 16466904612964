:root {
  --main-black: black;
  --main-red: #f50a00;
  --main-blue: #1632b6;
  --main-black: #12129d;
  --main-yellow: #FFCA03;
}

.contentofpara,
.headingpara {
  text-align: justify !important;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  font-size: 17px;
}
.headingpara p span{
    color: var(--main-red);
    
}
.headingpara p{
    font-weight: 700;
}

.headingofdemo h1 {
  color: var(--main-black);
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.headingofdemo h1 a {
  text-decoration: none;
  animation-name: blink;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: Infinite;
}
@keyframes blink {
  0% {
    color: red;
  }
  50% {
    color: white;
  }
  100% {
    color: red;
  }
}
.democlasses {
  margin-top: 2rem;
}
/* .contentofpara p{
    text-align: ;
} */

@media screen and (max-width: 600px) {
    .contentofpara p, .headingpara p{
        text-align: justify;
    }
}
