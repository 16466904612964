h1 {
  margin-top: 100px;
  text-align: center;
  font-size: 60px;
}
/* .addingImage{
  width: 30%;
  background-color: var(--main-yellow);
}
.addingimg1{
  width: 200%;
  background-color: var(--main-yellow);
}
.addingImage li a{
  width: 150%;
} */
.navbar11 {
  display: flex;
  align-items: center;
  /* background-color: var(--main-yellow); */
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
  z-index: 300 !important;
  position: sticky;
  top: 0;
}
.navbardisplay {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem 0rem 1rem;
  width: 28.85%;
}
.navbardisplay img {
  width: 40%;
}
#container {
  margin: 0 auto;
  max-width: 890px;
}
p {
  text-align: center;
}
.toggle,
[id^="drop"] {
  display: none;
}
nav {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: left;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  /* background-color: var(--main-yellow); */
  /* background-image: url(./../image/back4.jpg);  */
  background-repeat: no-repeat;
  /* background-size: contain; */
}

/* .uldesign{
  display: flex !important;
} */

nav a {
  display: block;
  padding: 0 18px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 10px;
  text-decoration: none;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

nav ul li ul li:hover {
  color: #ff5403;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

nav a:hover {
  color: #ff5403;
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

nav ul ul {
  display: none;
  position: absolute;
  top: 60px;
}

nav ul li:hover > ul {
  display: inherit;
}
.imaging111 {
  width: 140%;
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
}
.imaging11111{
  width: 110%;
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
}
.imaging1111 {
  width: 150%;
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
  position: relative;
  top: -50px;
  left: 229px;
  z-index: 200;
}

ul li a svg {
  width: 1.5rem;
  margin-right: 0.3rem;
  color: rgb(255, 0, 0);
}

.addingdisplay li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

nav ul ul li {
  width: 100%;
  float: none;
  display: list-item;
  position: relative;
}

nav ul ul ul li {
  /* position: relative;
  top: -60px;
  left: 170px; */
  z-index: 20;
  width: 104% !important;
}
.imaging1 {
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
}
.imaging11 {
  background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 180%;
  position: relative;
  top: -60px;
  left: 230px;
  z-index: 20;
  width: 170% !important;
}
.settingmain1 {
  display: none;
}

li > a:only-child:after {
  content: "";
}
.ulilist {
  display: flex !important;
}

/* Media Queries
  --------------------------------------------- */

@media all and (max-width: 600px) {
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }
  .imaging1111 {
    width: 100%;
    background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
    background-repeat: no-repeat;
    background-size: 100% 180%;
    position: initial;
    top: 0px;
    left: 233px;
    z-index: 200;
  }

  .settingMain {
    display: none !important;
  }

  /* .addingimg1 li a{
    width: 50%;
  } */
  .navbar11 {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-end;
    position: sticky;
    top: 0;
  }
  nav ul ul ul li{
    z-index: 20;
    width: 198% !important;
  }
  nav ul ul ul ul li {
    z-index: 20;
    width: 100% !important;
  }
  .navbardisplay img {
    width: 114%;
    /* display: none; */
  }
  .imaging11 {
    background-image: url(https://previews.123rf.com/images/leoedition/leoedition1708/leoedition170800284/83761291-back-to-school-background-education-banner.jpg);
    background-repeat: no-repeat;
    background-size: 100% 180%;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 62% !important;
  }

  nav {
    margin: 0;
  }
  nav a {
    padding-bottom: 0px;
  }

  nav a svg {
    width: 1.5rem !important;
    margin-right: 0.3rem !important;
    color: orange !important;
  }

  .toggle + a,
  .menu {
    display: none;
  }
  .toggle svg {
    width: 1.5rem;
    margin-right: 0.3rem;
    color: orange;
  }
  .ulilist {
    display: block !important;
  }
  .toggle1 {
    background-color: transparent;
    padding: 0 20px;
    color: white;
    font-size: 30px;
    font-weight: bolder;
    line-height: 50px;
    text-decoration: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .toggle {
    /* display: block; */
    background-color: transparent;
    padding: 0 20px;
    color: white;
    font-size: 16px;
    font-weight: bolder;
    line-height: 50px;
    text-decoration: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .toggle:hover {
    color: #ff5403;
  }

  [id^="drop"]:checked + ul {
    display: block;
  }

  nav ul li {
    display: block;
    width: 100%;
  }

  nav ul ul .toggle,
  nav ul ul a {
    padding: 0 40px;
  }

  nav ul ul ul a {
    padding: 0 10px;
  }
  nav a {
    line-height: 40px;
  }

  nav a:hover,
  nav ul ul ul a {
    color: #ff5403;
  }

  nav ul li ul li .toggle,
  nav ul ul a {
    color: #ff5403;
  }

  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
  }

  nav ul ul li:hover > ul,
  nav ul li:hover > ul {
    display: none;
  }

  nav ul ul li {
    display: block;
    width: 100%;
  }

  nav ul ul ul li {
    position: static;
  }
}

@media all and (max-width: 600px) {
  nav ul li {
    display: block;
    width: 94%;
  }
}
