/* .vertical-carousel1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px; /* Adjust the width as needed */
/* overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
} */

/* .carousel-container1 {
  height: 90px; /* Set the height to display 3 items at a time */
/* overflow: hidden; */
/* } */
/* 
.carousel-inner-container {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

.carousel-item1 {
  padding: 10px;
  text-decoration: none;
  color: #333;
  opacity: 0;
  transition: opacity 0.5s ease;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.carousel-item1.active {
  opacity: 1;
}

.control-button1 {
  cursor: pointer;
  background-color: #ddd;
  border: none;
  padding: 10px;
  margin: 2px;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;
}

.control-button1:hover {
  background-color: #ccc;
}

.control-button1.prev1 {
  align-self: flex-start;
}

.control-button1.next1 {
  align-self: flex-end;
}  */

:root {
  --main-black: black;
  --main-red: #f50a00;
  --main-blue: #1632b6;
  --main-black: #12129d;
  --main-yellow: #FFF323;
}

.ulvertical {
  display: flex;
  list-style: none;
}

.ulvertical li a {
  color: var(--main-red);
  font-weight: 700;
  text-decoration: none;
  font-size: 25px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 1rem;
  display: flex;
  align-items: center;
}

.ulvertical li a img {
  width: 7%;
  -moz-animation: blink normal 1s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1s infinite ease-in-out; /* IE */
  animation: blink normal 1s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* CardComponent.css */
.card25 {
  max-width: 80%; /* Adjust the max-width as needed for responsiveness */
  margin: 20px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.card-content25 {
  display: flex;
}

.image-container25 {
  margin-right: 15px;
}

.image-container25 img {
  max-width: 100%;
  height: 10rem;
  border-radius: 8px;
}
h6{
  padding-top: 2rem;
}

.text-container25 {
  flex: 1;
}

.card-footer25 {
  margin-top: 15px;
}

.footer-text25 {
  color: navy;
  font-size: larger;
  font-weight: 700;
}
.studymaterial1 h2 {
  color: var(--main--black);
  padding: 0.5rem;
  background-image: url(https://t3.ftcdn.net/jpg/04/47/39/32/360_F_447393243_O0ba7BX3bL41T3dFylrjgj6sXCaob5Rt.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}


@media only screen and (max-width: 600px) {
  .card-content25 {
    flex-direction: column;
  }
  .text-container25 h6{
    text-align: justify;
  }
  .image-container25 img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
