.footering{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: aqua; */
    background-image: url(https://t3.ftcdn.net/jpg/04/47/39/32/360_F_447393243_O0ba7BX3bL41T3dFylrjgj6sXCaob5Rt.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.aligning ul{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.textleft{
    text-align: left !important;
}
.footering a{
    text-decoration: none;
    font-size: 17px;
    background-color: transparent;
    font-weight: bold;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ona-text{
    text-shadow: 0px 4px 4px rgba(238, 5, 5, 0.25);
    font-size: 17px;
    color: red;
    font-weight: bold;
}

.text-dark{
    text-decoration: none !important;
    /* color: aqua !important; */
    text-align: right !important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: larger;
}

.text-dark:hover{
    color: red !important;
    font-size: larger;
    font-weight: bold;
}

.upperfooter{
    background-image: url(https://img.freepik.com/premium-photo/frame-notepads-colored-pencils-watercolors-pen-blue-background-copy-space_121837-5170.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .footering{
        flex-direction: column;
    }
}



.footerIcon ul {
    list-style-type: none;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem 2rem 0rem;
  }
  .footerIcon ul li {
    text-decoration: none;
    margin: 0.5rem;
  }

 
  .footerIcon ul li a img {
    width: 45px;
  }
  
  @media only screen and (max-width: 600px) {
    .footerIcon ul li a img {
      width: 45px;
    }
   
    .footerIcon ul {
      padding-left: 0;
    }
   
  }
  