.privacypolicysection{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}
/* a{
    width: 80% !important;
} */

.privacypolicydiv{
    width: 80%;
    margin-top: 30px; 
}