.successiconsdiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.successstorylower {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}
.successstorylower p {
  width: 100% !important;
}
.successstorylower {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem 0.5rem 2rem 0.5rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.successstory h1 {
  color: var(--main--black);
  padding: 0.5rem;
  background-image: url(https://t3.ftcdn.net/jpg/04/47/39/32/360_F_447393243_O0ba7BX3bL41T3dFylrjgj6sXCaob5Rt.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-size: xx-large;
}

.successstory p {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  font-weight: 600;
  color: var(--main--black);
  text-align: center;
}

.successstorylower svg {
  width: 4.5rem !important;
  margin: 1rem;
}

@media screen and (max-width: 600px) {
  .successstory p {
    text-align: justify;
  }
  .successiconsdiv {
    flex-direction: column;
  }
  .successstorylower {
    width: 80%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .successstorylower p {
    text-align: center;
    color: black;
  }
}
