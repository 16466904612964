.csirmention h1 {
  color: orange;
  font-size: xx-large;
  font-weight: 700;
  padding: 2rem;
}
.topcsir {
  background-image: url(https://img.freepik.com/premium-photo/back-school-education-banner-background_8087-1192.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  /* padding: 8rem; */
}
.innercsir {
  /* background-color: rgba(143, 111, 6, 0.463); */
  padding: 8rem;
}
.topgate {
  background-image: url(./../image/back10.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  /* padding: 8rem; */
}
.innergate {
  /* background-color: rgba(143, 111, 6, 0.463); */
  padding: 8rem;
}
.inforcsir {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.infor1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.infor1 img {
  width: 30%;
  border-radius: 2rem;
  margin-right: 2rem;
}
.inforcsir img {
  width: 30%;
  border-radius: 2rem;
}
.csirnetpara p {
  font-size: 17px;
  color: rgb(132, 128, 128);
  text-align: justify;
  width: 90%;
}
.csirnetpara1 p {
  font-size: 17px;
  color: rgb(132, 128, 128);
  text-align: end;
  width: 100%;
}
.csirnetpara h5 {
  font-weight: 700;
  color: var(--main-black);
}

.csirnetpara p a {
  color: var(--main-black);
  font-weight: 700;
  text-decoration: none;
}

.course-table-container {
  margin: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}
.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styled-table th {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #f5f5f5;
}

/* GateBiotechTable.css */

.gate-biotech-table-container {
  margin: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styled-table th {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #f5f5f5;
}
.csirnetpara1 {
  width: 100%;

  padding: 1rem;
}
.csirnetpara1 p {
  text-align: justify;
}
.csirnetpara1 p b {
  color: var(--main-black);
  font-size: xx-large;
}
.gate {
  padding-left: 1rem;
  color: var(--main-black);
}
.gatelifesci1 img,
.gatelifesci img {
  width: 30%;
  border-radius: 2rem;
}
.gatelifesci {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 1rem;
}
.gatelifesci1 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 1rem;
}
.gatelifesciinner p,
.gatelifesciinner h5 {
  margin-left: 2rem;
}
.gatelifesciinner h5,
.gatelifesciinner1 h5 {
  font-weight: 700;
  color: orange;
  font-size: xx-large;
}
.gatelifesciinner p,
.gatelifesciinner1 p {
  font-size: 17px;
  color: rgb(132, 128, 128);
  text-align: justify;
  width: 95%;
}

@media all and (max-width: 600px) {
  .csirmention h1 {
    padding: 1rem 0.2rem 1rem 0.2rem;
    font-size: x-large;
  }
  .csirnetpara1 p b {
    color: var(--main-black);
    font-size: x-large;
  }
  .inforcsir {
    flex-direction: column;
  }
  .infor1 {
    flex-direction: column;
  }
  .infor1 img {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
  .inforcsir img {
    width: 100%;
    margin-bottom: 1rem;
  }
  .csirnetpara p {
    width: 100%;
  }
  .gatelifesci,
  .gatelifesci1 {
    flex-direction: column;
  }
  .gatelifesci img,
  .gatelifesci1 img {
    width: 100%;
    margin-bottom: 1rem;
  }
  .gatelifesci1 {
    margin: 0rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .gatelifesci {
    margin: 0rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .gatelifesciinner p, .gatelifesciinner1 p {
    width: 100%;
    margin-left: 0rem;
  }
}
