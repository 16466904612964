/* .carousel {
  height: 100vh;
  perspective: 250px;
}
.carousel .carousel-item {
  width: 450px;
} */
.card1 {
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  width: 450px;
  height: 379px;
  color: #262626;
  border-radius: 100px;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
}
.img-area img {
  border-radius: 50%;
  border: 7px solid #fff;
  margin-top: -80px;
}

.box1 {
  background: #005bae;
  height: 120px;
  border-radius: 25px 25px 0 0;
}
.img-area {
  text-align: center;
}
.img-area img {
  width: 30%;
}
.main-text {
  padding: 8px 0;
  text-align: center;
}
.main-text h2 {
  font-weight: 900;
  font-size: 20px;
  margin: 0 0 20px;
  font-family: "Alfa Slab One", cursive;
}

.main-text p {
  font-size: 16px;
  padding: 0 35px;
}

.swiper {
  width: 100%;
}

.swiper-wrapper {
  width: 100%;
  height: 35em;
  display: flex;
  align-items: center;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper .card1 {
  width: 20em;
  height: 80%;
  background-color: #fff;
  border-radius: 2em;
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
  padding: 2em 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 2em;
}

.swiper-slide:not(.swiper-slide-active) {
  filter: blur(1px);
}

.card_image {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  border: 5px solid #e6b745;
  padding: 3px;
  margin-bottom: 2em;
  align-items: center;
  justify-content: center;
}

.card_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.card_content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card_text {
  text-align: center;
  font-size: 1.1em;
  margin: 1em 0;
}
