/* .slick-next:before, 
.slick-prev:before  {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev{
    display: block !important;
    position: relative;
    color: black;
}
.slick-slider {
    width: 93%;
} */

.w-100a {
    width: 100%!important;
    height: 40rem !important;
}

@media screen and (max-width: 600px) {
    .w-100{
        height: 100%;
    }
}


