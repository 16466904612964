.flot-btn{
    color: white;
    background-color: orange;
    font-weight: 700;
    font-size: larger;
    border-color:orange ;
}

.flot-btn:hover{
    color: white;
    background-color: rgb(255, 0, 0) !important;
    border-color:red;
}

.modal-content{
    background-image: url(./../image/image.png);
    background-repeat: no-repeat;
    background-size: 106% 108%;
    background-position:-3vh;
    /* background-color: var(--main-yellow); */
}
.btncolor{
    color: white;
    background-color: orange;
    font-weight: 700;
    font-size: larger;
}

.btncolor:hover{
    background-color: rgb(207, 135, 0);
    transition: 1s ease;
}
.btncolor:hover{
    transform: scale(1.2);
}