.headertop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem 0rem 0rem;
  background-color: transparent;
}
.headertop img {
  width: 12%;
  border-radius: 1rem;
}

.contactnumber p span {
  color: red;
  font-size: 20px;
  font-weight: 600;
}

.contactnumber p a {
  font-weight: bolder;
  font-size: 22px;
  color: orange;
  text-decoration: none;
  transition: color 0.5s ease;
  /* text-shadow: 0px 1px 2px rgb(228, 174, 75); */
}

.contactnumber p a:hover {
  color: lawngreen;
  text-shadow: 0px 4px 4px rgba(0, 255, 8, 0.25);

}

/* .contactnumber {
  border: 2px solid rgb(18, 18, 157);
  border-radius: 0.5rem;
} */
.contactnumber p {
  padding: 0rem;
  margin-bottom: 0rem;
}
.marque {
  color: var(--main-red);
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  width: 55%;
}
.headertag h2 {
  color: white;
  font-size: 25px;
  font-weight: 800;
  padding: 1.5rem;
  /* padding-right: 2rem; */
}
.headertag {
  background-color: var(--main-red);
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 81% 28%, 74% 0);
  width: 30%;
}

@media screen and (max-width: 600px) {
  .headertop img {
    width: 30%;
  }
  .headertop {
    padding: 0rem;
    padding-right: 0.5rem;
    justify-content: center;
    /* display: none; */
    /* width: 100%; */
    font-style: italic;
  }
  .headertag{
    /* display: none; */
    width: 100%;
  }
  .contactnumber{
    display: none;
  }
  .contactnumber p a,
  .contactnumber p span {
    font-size: 17px;
  }
  .contactnumber p {
    padding: 0.2rem;
  }
  .marque {
    display: none;
  }
  .headertag h2 {
    font-size: 21px;
  }
}


@keyframes blinkercall {
  0%, 100% {
    transform: rotate(-20deg) translateX(-2px);
  }
  50% {
    transform: rotate(20deg) translateX(2px);
  }
}

.element-to-animate {
  animation: blinkercall 3s linear infinite;
}