.studyandtable {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .studyandtable {
    flex-direction: column;
  }
}
