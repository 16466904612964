
.blogdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}
.blogheading h1{
    background-image: url(./../image/creative-synthesis-education-background_2755178.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-size: cover; */
    padding: 2rem;
}

.blogheading{
    text-align: center;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 85%;  */
    padding: 20px 40px 0px 40px;
}

.blogheading p{
    text-align: center;
}

.blogrow{
    width: 85%;
    display: flex;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 40px;
}

.blogcolumn {
    flex: 1;
    width: 30%;
    margin: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.blogcolumn p{
    margin-top: 20px;
    font-weight: 600;
}
.blogimage{
    height: 300px;
    width: 100%;
    vertical-align: top;
    border-radius: 40px 10px 80px 10px;
}

@media screen and (max-width: 600px) {
    .blogrow{
        flex-direction: column;
        padding: 0;
    }
    .blogcolumn{
        width: 90%;
    }
  }

