td{
    text-align: justify;
    padding: 0.7rem !important;
    border: 2px solid orange;
}
.table{
    border: 2px solid orange;
}
th{
    border: 2px solid orange;
}


/* @media only screen and (max-width: 600px) {
    td{
        text-align: justify;
        padding: 0.7rem !important;
        border: 2px solid orange;
    }
    .table{
        border: 2px solid orange;
    }
    th{
        border: 2px solid orange;
    }
} */