.awardgallery {
  width: 30%;
}

.awardgallery h2 {
  color: var(--main--black);
  /* background-color: var(--main-yellow); */
  background-image: url(https://whitbyshores.ddsb.ca/en/resourcesGeneral/Featured-Image/newsletterpicture.jpg);
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
}

.w-100 {
  width: 100% !important;
  height: 26rem;
}

@media screen and (max-width: 600px) {
    .awardgallery {
        width: 100%;
      } 
      .w-100 {
        width: 100% !important;
        height: 15rem;
      }
}
