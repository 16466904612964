.directorhead {
  background-image: url(https://t3.ftcdn.net/jpg/03/69/00/58/360_F_369005893_UYbUUUHDiB0HT7vs7EpO0JZ4vf830R5K.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-color: var(--main-blue); */
}
.directorhead h1 {
  color: white;
  padding: 8rem;
  font-size: 70px;
  font-weight: 700;
  /* background-color: rgba(0, 200, 255, 0.463); */
}

.directordesk {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}
.directormssg {
  width: 65%;
}
.directormssg p {
  text-align: justify;
  font-size: 18px;
}
.directordesk img {
  border-radius: 2rem;
}

/* about institute page  */
.institutehead {
  background-image: url(https://t3.ftcdn.net/jpg/03/69/00/58/360_F_369005893_UYbUUUHDiB0HT7vs7EpO0JZ4vf830R5K.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.institutehead h1 {
  color: white;
  padding: 8rem;
  font-size: 70px;
  font-weight: 700;
  /* background-color: rgba(0, 200, 255, 0.463); */
}
.institutepara {
  width: 100%;
  padding: 2rem;
}
.institutepara p {
  text-align: justify;
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .directordesk {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0.7rem;
  }
  .directormssg {
    width: 100%;
  }
  .directorhead h1 {
    padding: 4rem;
    font-size: 38px;
  }
  .institutehead h1 {
    padding: 4rem;
    font-size: 35px;
  }
  .directordesk img {
    width: 85%;
  }
  .institutepara {
    padding: 0.7rem;
  }
}
