.mobile-margin-top {
  border: 0;
}

.howtoreachrow {
  width: 100%;
  display: flex;
  padding: 10px 10px;
  align-items: baseline;
}
.checkoutcolumn {
  width: 42%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howtoreachcolumn {
  flex: 1;
  margin: 20px;
}

.howtoreachcolumn p {
  text-align: justify;
}

.howtoreachinput {
  width: 100%;
  padding: 10px;
}

.howtoreachbutton {
  border-radius: 20px;
  border: 2px solid #ffd301;
  padding: 12px;
  background-color: white;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.howtoreachbutton:hover {
  background-color: #ffd301;
  /* transform: scale(1.1); */
}

.howtoreachbutton1 {
  border-radius: 20px;
  border: none;
  padding: 12px;
  background-color: #ffd301;
  transition: transform 0.3s ease;
  margin: 20px 0px;
}

.howtoreachspan {
  color: orange;
}

@media screen and (max-width: 600px) {
  .mobile-margin-top {
    margin-top: 0;
  }

  .howtoreachrow {
    padding: 0px;
    flex-direction: column;
  }
  .checkoutcolumn {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
