:root {
  --main-black: black;
  --main-red: #f50a00;
  --main-blue: #1632b6;
  --main-black: #12129d;
  --main-yellow: #FFCA03;
}

.regularclass {
  margin: 3rem 0rem 3rem 0rem;
}
.regularfeeheading h2 {
  color: var(--main--black);
  padding: 0.5rem;
  background-color: var(--main-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  background-image: url(https://t3.ftcdn.net/jpg/04/47/39/32/360_F_447393243_O0ba7BX3bL41T3dFylrjgj6sXCaob5Rt.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.regularfeeheading h2 img {
  width: 2%;
  -moz-animation: blink normal 1s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1s infinite ease-in-out; /* IE */
  animation: blink normal 1s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* button css */

/* CSS */
.button-62 {
  background: linear-gradient(
    to bottom right,
    #FFCA03,
    #FF5403
  );
  border: 0;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:not([disabled]):hover {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem var(--main-yellow),
    0.125rem 0.125rem 1rem var(--main-red);
}
.allregularclasslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.onecardregular {
  width: 25%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 2rem;
}
.onecardregular p {
  text-align: justify;
  width: 85%;
}
.onecardregular h2 {
  font-size: 20px;
  font-weight: 700;
  color: #FFCA03;
  text-align: center;
}
svg {
  width: 2rem;
  height: 3rem;
  color: var(--main-red);
}

@media screen and (max-width: 600px) {
  .allregularclasslist {
    flex-direction: column;
  }
  .onecardregular {
    width: 78%;
    height: 25%;
  }
  .regularfeeheading h2{
    text-align: center;
  }
}
